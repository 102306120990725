"use client"

import { env } from "@/env.mjs"
import { datadogRum } from "@datadog/browser-rum"

const domainEnvs: Record<string, string> = {
  "gotorhythms.ai": "dev1",
  "getrhythms.ai": "demo",
  "rhythms.ai": "usprod",
  "loca.it": env.NEXT_PUBLIC_DD_ENV,
}

const getDatadogEnv = () => {
  if (typeof window === "undefined") {
    return env.DD_ENV
  }
  const hostname = window.location.hostname
  const domain = hostname.split(".").slice(-2).join(".")
  return domainEnvs[domain] ?? env.NEXT_PUBLIC_DD_ENV
}
/* 
https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm 
https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum#setup-rum 
*/

datadogRum.init({
  applicationId: env.NEXT_PUBLIC_DD_APP_ID,
  clientToken: env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "webui-client",
  env: getDatadogEnv(),
  version: env.NEXT_PUBLIC_DD_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true, // TODO: Enables the automatic collection of potentially sensitive or private data within user clicks in your application. To replace sensitive data https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask",
  // Specify URLs to propagate trace headers for connection between RUM and backend trace. Given we promote the same build to multiple environments, we need to specify all the environment URLs.
  allowedTracingUrls: [
    ...Object.keys(domainEnvs).map(
      (domain) => new RegExp(`https://.*\\.${domain}`)
    ),
    (url) => url.startsWith("http://localhost"),
  ],
})

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code above will run client-side
  return null
}
