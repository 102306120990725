import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/accordion/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/alert/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/autocomplete/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/avatar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/badge/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/breadcrumbs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/button/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/calendar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/card/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/checkbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/chip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/date-input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/date-picker/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/drawer/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/dropdown/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/form/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ResizablePanel"] */ "/webui/node_modules/@heroui/framer-utils/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/image/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/input-otp/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/link/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/listbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/modal/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/navbar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/pagination/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/popover/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/progress/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/radio/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/ripple/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/scroll-shadow/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/select/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/skeleton/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/slider/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/snippet/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/switch/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/system/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/table/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/tabs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/tooltip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/@heroui/user/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/webui/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/webui/src/components/instrumentation/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProviderWrapper"] */ "/webui/src/providers/query-client-provider.tsx");
;
import(/* webpackMode: "eager" */ "/webui/src/styles/globals.css");
